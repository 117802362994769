import { CameraStatus, TimelinePrecision } from "@evercam/shared/types"
import { TimelineChartType } from "@evercam/ui"

export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY
export const MONTH = 30 * DAY
export const YEAR = 365 * DAY

export const TimelineSharedConfig = {
  label: "",
  height: 80,
  chartType: TimelineChartType.Milestones,
  dots: true,
  milestonesTransitionDuration: 300,
  milestonesLineHeight: 25,
}

export type TimelinePlayerBreakpoint = {
  precision: TimelinePrecision
  breakpoint: number
}

/**
 * Used to determine when to switch to a different precision, given the interval's length in days
 * example: if the interval length == 100, then the precision should be 'week':
 * because 100 > 45 ("week" breakpoint)
 * and 100 < 120 ("month" breakpoint)
 */
export const TLPlayerDefaultPrecisionBreakpoints: TimelinePlayerBreakpoint[] = [
  {
    precision: TimelinePrecision.Year,
    breakpoint: DAY * 732,
  },
  {
    precision: TimelinePrecision.Month,
    breakpoint: DAY * 120,
  },
  {
    precision: TimelinePrecision.Week,
    breakpoint: DAY * 45,
  },
  {
    precision: TimelinePrecision.Day,
    breakpoint: DAY * 15,
  },
  {
    precision: TimelinePrecision.Hour,
    breakpoint: DAY * 1.5,
  },
  {
    precision: TimelinePrecision.Events,
    breakpoint: 0,
  },
]

/**
 * Used to determine whether to fetch new data when the timeline visible interval changes.
 * example: if the precision == month and the visible interval changes by more than 2 months, then fetch the data.
 */
export const TLPlayerDefaultRefreshBreakpoints: TimelinePlayerBreakpoint[] = [
  {
    precision: TimelinePrecision.Year,
    breakpoint: YEAR * 2,
  },
  {
    precision: TimelinePrecision.Month,
    breakpoint: MONTH * 2,
  },
  {
    precision: TimelinePrecision.Week,
    breakpoint: WEEK * 2,
  },
  {
    precision: TimelinePrecision.Day,
    breakpoint: DAY * 2,
  },
  {
    precision: TimelinePrecision.Hour,
    breakpoint: HOUR * 2,
  },
  {
    precision: TimelinePrecision.Events,
    breakpoint: DAY * 0.5,
  },
]

/**
 * Used to determine the default chart type for a given precision
 */
export const TLPlayerDefaultChartTypeByPrecision = {
  [TimelinePrecision.Year]: TimelineChartType.LineGraph,
  [TimelinePrecision.Month]: TimelineChartType.LineGraph,
  [TimelinePrecision.Week]: TimelineChartType.LineGraph,
  [TimelinePrecision.Day]: TimelineChartType.LineGraph,
  [TimelinePrecision.Hour]: TimelineChartType.LineGraph,
  [TimelinePrecision.Minute]: TimelineChartType.Bars,
  [TimelinePrecision.Events]: TimelineChartType.Bars,
}

export const TimelineColors = {
  primary: "#629efc",
  primaryDark: "#1f77b4",
  success: "#42c01f",
  warning: "#d36511",
  gateReport: "#1b77d2",
  motion: "#d36511",
  anpr: "#519640",
  exNvr: "#7a57ee",
  processingData: "#1f77b4",
  copilot: "#c4282f",
  user: "#6b48c0",
  bimMilestones: "#E59BE9",
  compareMarker: "rgba(17,97,182,0.91)",
  droneMilestone: "rgba(37,77,122,0.91)",
  _360Milestone: "rgba(37,77,122,0.91)",
  mediaHubMilestone: "rgba(37,77,122,0.91)",
  mobileCaptureMilestone: "rgba(37,77,122,0.91)",
  comments: "rgba(17,68,182,0.91)",
  placeholder: "transparent",
  forbiddenInterval: "rgba(196,196,196,0.72)",
  error: "#673AB7",
  [CameraStatus.Decommissioned]: "#115e59",
  [CameraStatus.Offline]: "#dc2626",
  [CameraStatus.Online]: "#62ae4f",
  [CameraStatus.OfflineScheduled]: "#facc15",
  [CameraStatus.Waiting]: "#f97316",
  [CameraStatus.WaitingForSiteVisit]: "#a3a3a3",
  [CameraStatus.OnHold]: "#f43f5e",
  [CameraStatus.UnderMaintenance]: "#f87171",
}
