export default {
  VH_STATUS: "vh_status",
  CR_UPDATED: "cr_updated",
  CR_CREATED: "cr_created",
  ONLINE: "online",
  OFFLINE: "offline",
  ON_HOLD: "on_hold",
  WAITING: "waiting",
  DECOMMISSIONED: "decommissioned",
  UNDER_MAINTENANCE: "under_maintenance",
  WAITING_FOR_SITE_VISIT: "waiting_for_site_visit",
  SMS: "sms",
  PRIVATE_NOTE: "private_note",
  PUBLIC_NOTE: "public_note",
  SIM: "sim",
  LOGIN: "login",
  LOGOUT: "logout",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  DEVICE_SETTINGS: "device_settings",
  LINK_SIM_WITH_ROUTER: "link_sim_with_router",
  UNLINK_SIM_FROM_ROUTER: "unlink_sim_from_router",
  LINK_CAMERA_WITH_ROUTER: "link_camera_with_router",
  UNLINK_CAMERA_FROM_ROUTER: "unlink_camera_from_router",
  SHARE: "share",
  UPDATE_SHARE: "update_share",
  DELETE_SHARE: "delete_share",
  SHARE_REQUEST: "share_request",
  DELETE_SHARE_REQUEST: "delete_share_request",
  TRANSFER_OWNERSHIP: "transfer_ownership",
  BIM_NOTE: "bim_note",
  GATE_REPORT_NOTE: "gate_report_note",
}
